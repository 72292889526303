import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import ExifReader from 'exifreader';
import DatePicker from "../components/DatePicker"
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
const { useState, useEffect } = React
const IndexPage = () => {
    const location = useLocation();
    const qs = new URLSearchParams(location.search)
    const [type, setType] = useState(qs.get('type') || "")
    const [typeItem, setTypeItem] = useState(null)
    const [payload, setPayload] = React.useState({
        name: '',
        startDate: '',
        endDate: '',
        image: ''
    })
    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files)
        const file = files[0]
        const parseFile = new Parse.File(file.name, file)
        return await parseFile.save()
    }

    useEffect(() => {
        if (type) {
            getType(type)
        }
    }, [type])

    const getType = async () => {
        const [item] = await Parse.Cloud.run('queryItems', {
            query: {
                equalTo: ['type', type]
            }
        })
        setTypeItem(item.data)
    }

    const handleCreateInstance = async () => {
        Parse.Cloud.run(`${type}Create`, {
            data: payload
        })
    }

    return <Layout>
        <Grid classes="col-12 col-xl-4">
            <h3> Create {type} </h3>
            <Form>
                {
                    Object.keys(typeItem?.attributes || {}).map(e => <Form.Group className="mb-3">
                        <Form.Label> {e} </Form.Label>
                        <Form.Control
                            type={typeItem?.attributes[e]}
                            value={typeItem?.attributes[e] === "file" ? undefined : payload?.[e]}
                            onChange={async (ev) => {
                                if (ev.target.type === 'file') {
                                    const file = await handleFileUpload(ev)
                                    console.log()
                                    setPayload({
                                        ...payload,
                                        [e]: file.url()
                                    })
                                } else {
                                    setPayload({
                                        ...payload,
                                        [e]: ev.target.value,
                                    })
                                }
                            }}
                        />
                    </Form.Group>)
                }
                <Form.Group className="mb-3">
                    <Button onClick={handleCreateInstance}> Create </Button>
                </Form.Group>
            </Form>
        </Grid>
    </Layout>
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
